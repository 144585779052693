import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import router from '../router/index.js';
import crypto from 'crypto';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: (localStorage.getItem('isLogin') ? true : false),
    user: JSON.parse(localStorage.getItem('user')) || null,
    
    userSearchKeyword: '', // 사용자 목록 검색어

    // 마지막 조회 상품 카테고리
    lastViewProductCategoryCode: localStorage.getItem('lastViewProductCategoryCode') ? localStorage.getItem('lastViewProductCategoryCode') : '',

    // 주문 정보
    orderDatas: JSON.parse(localStorage.getItem('orderDatas')) || [],
  },
  getters: {
  },
  mutations: {
    login: (state) => {
      state.isLogin = true;
    },
    logout: (state) => {
      state.isLogin = false;
    },
    setUser: (state, userObj) => {
      state.user = userObj;
    },
    
    setUserSearchKeyword: (state, keyword) => {
      state.userSearchKeyword = keyword;
    },

    setLastViewProductCategoryCode: (state, lastViewProductCategoryCode) => {
      state.lastViewProductCategoryCode = lastViewProductCategoryCode;
    },

    setOrderDatas: (state, orderDatas) => {
      state.orderDatas = orderDatas;
    },
  },
  actions: {
    async login({ commit }, userInfo) {
      let loginInfo = {
        type: userInfo.type,
        id: userInfo.id,
        pwd: userInfo.pwd,
      }
      loginInfo = encrypt(JSON.stringify(loginInfo));
      const res = await axios.post('/api/auth/login', {loginInfo: loginInfo}, {
        validateStatus: function (status) {
          return status < 500;
        }
      });
      
      if(res.status === 200) { // 로그인 성공
        // user 정보 저장
        const user = res.data.user;
        localStorage.setItem('user', JSON.stringify(user));
        commit('setUser', user);
        // 로그인 상태 변경
        localStorage.setItem('isLogin', true);
        commit('login');
        if(user.type === 0) {
          const pathname = window.location.pathname;
          if(pathname.includes('admin')) {
            router.replace({name: 'admin-dashboard'});
          } else {
            window.location.replace(window.location.origin + window.location.pathname);
          }
        } else if(user.type === 1) {
          window.location.replace(window.location.origin + window.location.pathname);
          // router.replace({name: 'home'}).catch(() => {
          //   router.go(0);
          // });
        }
      } else if(res.status === 423) {
        alert('아직 승인되지 않은 아이디 입니다.');
      } else if(res.status === 403) {
        alert('아이디/비밀번호를 확인해주세요.');
      }
    },
    async encryptedLogin({ commit }, loginInfo) {
      const res = await axios.post('/api/auth/check/encrypted-login', {loginInfo: loginInfo}, {
        validateStatus: function (status) {
          return status < 500;
        }
      });
      
      if(res.status === 200) { // 로그인 성공
        // user 정보 저장
        const user = res.data.user;
        localStorage.setItem('user', JSON.stringify(user));
        commit('setUser', user);
        // 로그인 상태 변경
        localStorage.setItem('isLogin', true);
        commit('login');
        if(user.type === 0) {
          router.replace({name: 'admin-dashboard'});
        } else if(user.type === 1) {
          window.location.replace(window.location.origin + window.location.pathname);
          // router.replace({name: 'home'}).catch(() => {
          //   router.go(0);
          // });
        }
      } else if(res.status === 423) {
        alert('아직 승인되지 않은 아이디 입니다.');
      } else if(res.status === 403) {
        alert('아이디/비밀번호를 확인해주세요.');
      }
    },
    async logout({ commit }) {
      const res = await axios.post('/api/auth/logout',{}, {
        validateStatus: function (status) {
          return status < 500;
        }
      });
      if(res.status === 200 || res.status === 401) { // 로그아웃 성공 or refresh 실패
        localStorage.removeItem('user');
        localStorage.removeItem('isLogin');
        localStorage.removeItem('orderDatas');
        localStorage.removeItem('lastViewProductCategoryCode');
        commit('logout');
        window.location.replace(window.location.origin + window.location.pathname);
      }
    },
    setUserName({commit}, userName) {
      let userData = JSON.parse(localStorage.getItem('user'));
      if(userData.type === 0) { // 관리자 이름 변경인 경우
        userData.name = userName;
      } else if(userData.type === 1) { // 일반회원 이름 변경인 경우
        userData.company = userName;
      }
      localStorage.setItem('user', JSON.stringify(userData));
      commit('setUser', userData);
    },

    setUserSearchKeyword({commit}, keyword) {
      commit('setUserSearchKeyword', keyword);
    },

    setLastViewProductCategoryCode({commit}, lastViewProductCategoryCode) {
      localStorage.setItem('lastViewProductCategoryCode', lastViewProductCategoryCode);
      commit('setLastViewProductCategoryCode', lastViewProductCategoryCode);
    },

    setOrderDatas({commit}, orderDatas) {
      localStorage.removeItem('orderDatas');
      localStorage.setItem('orderDatas', JSON.stringify(orderDatas));
      commit('setOrderDatas', orderDatas);
    },
  },
  modules: {
  }
})


function encrypt(val) {
  // const RSA_PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----\nMIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgGn/Dv99fxyNTEDN5PNOkfdSWNrk\nH/V63oA/qIK5KWPUjPK0Ari8Goeejdj845MomzrkpdT71oYtQrc6pxjnn9hCzGaM\n98NsonetEOUUi7Yzg2bjgOTuTAM0C9et+c+oeGyfUJkDAeQo2xvy2zQx5Uw/q+Fb\nLX6W8T/XF3fE3KSFAgMBAAE=\n-----END PUBLIC KEY-----'
  const RSA_PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAiXqxFRL2JiQYxi4KsFMF\nD9A/3txEf+I61TkfIqfHJxO2+KP7UAi+PbIHQhbfHpS7LwFHRKv/INrgMjm2jNVd\neNgKrWpWsWGUx8mZH46ER4PyNfTe5SDPdC2TNae8TSFjd/DY9Yo5dzkgpu0zDOfT\nr7akoQ5YPTtB3DLWUtPc7Tugsv1am+w7sveIygh3gxB8ujj095QmwVyNEojt8AXn\n/gpZp3SQRuvliTcTxYbo0DX3At5ExBMRPE7nT4h+kcbmK0M5+6D6jFOTNZJdGsI+\nzyhzt1sssnRTP2vhCk5FeueXVjubfv1L8vJlTcIVnLQhfSUecWHWBJs+LcoFCBne\nN59YTqjgpkzdIXPvdhJ7gBZoLdvvj1m3LcxaQcLYvtcGXOUJtH0WKJyVkAvZ4xj3\nrnU9G8wql/TzBEhscGr+ldxBZKTX5MUQkzpEHlrKFIaRs+4iGr1V6dd3NdckfAkH\nHGjeID+OoWkE5T+z5YZK87UdTBpuWMos9stkYucUtmJBX/rrBydVKvZnr9txesnT\nHxuz/8P5NTBAE8FFrXZbpss5JKBP53Xk6yNVGwrxng7gkwmayFDfqX4GGSj+yEb6\nc9om0di34s3LEr8zG9+q2Tbs3m/FSd5+V+0l827RLihQT+n432WShbO1gV5MD1Ro\nVzHcUu6NSTrUhbGEfm7LRysCAwEAAQ==\n-----END PUBLIC KEY-----'

  const buffer = Buffer.from(val);
  const encrypted = crypto.publicEncrypt(RSA_PUBLIC_KEY, buffer);
  return encrypted.toString("hex");
}