import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import axios from 'axios';
Vue.prototype.axios = axios;

import crypto from 'crypto';
Vue.prototype.crypto = crypto;

import common from './plugins/common.js';
Vue.use(common);

import excel from './plugins/excel.js';
Vue.use(excel);

// 쿠키
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

