import crypto from 'crypto';

export default {
  install(Vue) {
    // 전역 함수 선언
    // status error 처리
    Vue.prototype.$checkStatus = async function(status) {
      if(status === 401) {
        await this.$store.dispatch('logout');
      } else if(status === 403) { // 권한 없음
        this.$router.replace({name: 'home'});
      }
    },
    Vue.prototype.$bsnsNum = (val) => {
      return String(val).replace(/[^0-9]/g, '').slice(0,10).replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, '$1-$2-$3').replace(/-{1,2}$/g, '');
    },
    Vue.prototype.$encrypt = (val) => {
      // const RSA_PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----\nMIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgGn/Dv99fxyNTEDN5PNOkfdSWNrk\nH/V63oA/qIK5KWPUjPK0Ari8Goeejdj845MomzrkpdT71oYtQrc6pxjnn9hCzGaM\n98NsonetEOUUi7Yzg2bjgOTuTAM0C9et+c+oeGyfUJkDAeQo2xvy2zQx5Uw/q+Fb\nLX6W8T/XF3fE3KSFAgMBAAE=\n-----END PUBLIC KEY-----'
      const RSA_PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAiXqxFRL2JiQYxi4KsFMF\nD9A/3txEf+I61TkfIqfHJxO2+KP7UAi+PbIHQhbfHpS7LwFHRKv/INrgMjm2jNVd\neNgKrWpWsWGUx8mZH46ER4PyNfTe5SDPdC2TNae8TSFjd/DY9Yo5dzkgpu0zDOfT\nr7akoQ5YPTtB3DLWUtPc7Tugsv1am+w7sveIygh3gxB8ujj095QmwVyNEojt8AXn\n/gpZp3SQRuvliTcTxYbo0DX3At5ExBMRPE7nT4h+kcbmK0M5+6D6jFOTNZJdGsI+\nzyhzt1sssnRTP2vhCk5FeueXVjubfv1L8vJlTcIVnLQhfSUecWHWBJs+LcoFCBne\nN59YTqjgpkzdIXPvdhJ7gBZoLdvvj1m3LcxaQcLYvtcGXOUJtH0WKJyVkAvZ4xj3\nrnU9G8wql/TzBEhscGr+ldxBZKTX5MUQkzpEHlrKFIaRs+4iGr1V6dd3NdckfAkH\nHGjeID+OoWkE5T+z5YZK87UdTBpuWMos9stkYucUtmJBX/rrBydVKvZnr9txesnT\nHxuz/8P5NTBAE8FFrXZbpss5JKBP53Xk6yNVGwrxng7gkwmayFDfqX4GGSj+yEb6\nc9om0di34s3LEr8zG9+q2Tbs3m/FSd5+V+0l827RLihQT+n432WShbO1gV5MD1Ro\nVzHcUu6NSTrUhbGEfm7LRysCAwEAAQ==\n-----END PUBLIC KEY-----'

      const buffer = Buffer.from(val);
      const encrypted = crypto.publicEncrypt(RSA_PUBLIC_KEY, buffer);
      return encrypted.toString("hex");
    }
    // 전역 filter 선언
    Vue.filter('comma', function(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }),
    Vue.filter('yyyymmdd', function(val) {
      if(val === '') return '';

      const date = new Date(val);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      month = month >= 10 ? month : '0' + month;
      day = day >= 10 ? day : '0' + day;

      return `${year}-${month}-${day}`;
    }),
    Vue.filter('yyyymmddHHmm', function(val) {
      if(val === '') return '';

      const date = new Date(val);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();

      month = month >= 10 ? month : '0' + month;
      day = day >= 10 ? day : '0' + day;
      hour = hour >= 10 ? hour : '0' + hour;
      minute = minute >= 10 ? minute : '0' + minute;

      return `${year}-${month}-${day} ${hour}:${minute}`;
    }),
    Vue.filter('phoneDash', function(val) {
      if(val === '' || val === undefined) return '';

      val = val.replace(/[^0-9]/g, "");

      let result = [];
      let restNumber = "";

      // 지역번호와 나머지 번호로 나누기
      if (val.startsWith("02")) {
        // 서울 02 지역번호
        result.push(val.substr(0, 2));
        restNumber = val.substring(2);
      } else if (val.startsWith("1")) {
        // 지역 번호가 없는 경우
        // 1xxx-yyyy
        restNumber = val;
      } else {
        // 나머지 3자리 지역번호
        // 0xx-yyyy-zzzz
        result.push(val.substr(0, 3));
        restNumber = val.substring(3);
      }

      if (restNumber.length === 7) {
        // 7자리만 남았을 때는 xxx-yyyy
        result.push(restNumber.substring(0, 3));
        result.push(restNumber.substring(3));
      } else {
        result.push(restNumber.substring(0, 4));
        result.push(restNumber.substring(4));
      }

      return result.filter((val) => val).join("-");
    })
  }
}