<template>
<v-container fluid class="pa-0">
  <v-row no-gutters>
    <v-img :src="require('@/assets/mainPage/mainBanner.png')" :height="brackpoint ? '619': '385'"></v-img>
  </v-row>
  <v-row no-gutters justify="center">
    <v-col cols="12">
      <v-card flat style="max-width:1280px;" class="mx-auto">
        <v-toolbar flat class="transparent product-title pl-10" width="100%">
          <v-toolbar-title>BEST PRODUCT</v-toolbar-title>
        </v-toolbar>
        <v-col>
          <v-row no-gutters>
            <v-col
              v-for="(product, i) in products" :key="i"
              cols="6" md="3" class="mb-4 px-2"
            >
              <v-card flat>
                <v-img
                  :src="product.product.thumbnail ? '/'+product.product.thumbnail : require('@/assets/product/empty.png')"
                  max-height="300" max-width="300" aspect-ratio="1" class="mx-auto" contain
                  @click="$router.push({name: 'products', query: {id: product.product.id}})"
                  style="cursor:pointer;"
                ></v-img>
                <div
                  @click="$router.push({name: 'products', query: {id: product.id}})"
                  style="cursor:pointer;"
                >{{product.product.name}}</div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
      <v-card flat tile class="mx-auto" :width="swiperWidth" style="max-height:370px; max-width:1280px; margin-top:2vw;margin-bottom:6vw;">
        <swiper class="swiper" :options="swiperOption">
          <template
            v-for="(youtube, i) in selectYoutubeList"
          >
            <swiper-slide :key="i">
              <v-card flat tile style="width:100%">
                <!-- <iframe width="100%" height="315" :src="youtube.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
              </v-card>
            </swiper-slide>
          </template>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="d-flex justify-end align-center pr-2 pb-3">
          <div
            style="font-size:14px; cursor:pointer;"
            @click="youtubeLink"
          >
            더보러 가기
            <v-icon>mdi-arrow-right-thin</v-icon>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
  <!-- 팝업 배너 -->
  <v-dialog
    v-model="popupDialog"
    width="500"
  >
    <v-card>
      <v-card-text class="pt-4" style="cursor: pointer;" @click="pdfDialog=true">
        <v-img :src="popupBanner ? popupBanner.img : ''"></v-img>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="todayNosee">오늘 하루 안보기</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="popupDialog = false">닫기</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- end 팝업 배너 -->
  <!-- 팝업 배너 -->
  <v-dialog
    v-model="pdfDialog"
  >
    <v-card>
      <iframe title="PDF" width="100%" height="600px" :src="`/pdfjs-4.0.189-legacy-dist/web/viewer.html?file=${documentUrl}#zoom=page-width`"></iframe>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="pdfDialog = false">닫기</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- end 팝업 배너 -->
</v-container>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  
  name: 'HomeView',

  components: {
    Swiper,
    SwiperSlide
  },
  props: ['brackpoint'],
  data() {
    return {
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false,
        // },
      },
      youtubeList: [
        {id: 0, title: '[Mobil Super] 터보 GDI 프로텍션 5W-30', url: 'https://www.youtube.com/embed/OfGSEgvI2JQ'},
        {id: 1, title: '[Mobil Super] 스마트 플러스 프로텍션 0W-20', url: 'https://www.youtube.com/embed/C9F2elACk6g'},
        {id: 2, title: '[Mobil Super] 터보 프로텍션 0W-30', url: 'https://www.youtube.com/embed/zgRK3IA1PJc'},
        {id: 3, title: '[Mobil] 왜 모빌전문점을 이용해야할까요 ?', url: 'https://www.youtube.com/embed/CjEl2bz84_k'},
        {id: 4, title: '[Mobil Super] 엔진오일이 왜 필요할까? - 엔진오일 잘난척 매뉴얼', url: 'https://www.youtube.com/embed/ujhzaNqe5lo'},
        {id: 5, title: '[Mobil Super] 엔진오일은 언제 교환하나? - 엔진오일 잘난척 매뉴얼', url: 'https://www.youtube.com/embed/PqG9wFHfSTQ'},
        {id: 6, title: '[Mobil Super] 5W-30은 무슨 뜻? - 엔진오일 잘난척 매뉴얼', url: 'https://www.youtube.com/embed/L0iR_7y3yMc'},
        {id: 7, title: '[Mobil Super] 광유와 합성유, 뭐가 다를까? - 엔진오일 잘난척 매뉴얼', url: 'https://www.youtube.com/embed/YbQwRczlkZs'},
        {id: 8, title: '[Mobil Super] 하이웨이 프로텍션', url: 'https://www.youtube.com/embed/VN-BPn0P2vY'},
        {id: 9, title: '[Mobil Super] 전용 엔진오일이 뭐지? - 엔진오일 잘난척 매뉴얼', url: 'https://www.youtube.com/embed/5Mwx1pJfL2Q'},
        {id: 10, title: '[Mobil Super] 하이브리드에 엔진오일은? - 엔진오일 잘난척 매뉴얼', url: 'https://www.youtube.com/embed/2EOzby4I_Zc'},
        {id: 11, title: '[Mobil Super] 엔진오일이 까만 이유는? - 엔진오일 잘난척 매뉴얼', url: 'https://www.youtube.com/embed/oD7y-zCD7zo'},
        {id: 12, title: '[Mobil Super] 엔진에 두 가지 필터, 왜 중요하지? - 엔진오일 잘난척 매뉴얼', url: 'https://www.youtube.com/embed/xpZOWGE5jgU'},
        {id: 13, title: '[Mobil] 모빌여지도 - 마스터 지프', url: 'https://www.youtube.com/embed/cU9l6Cqq7ZU'},
        {id: 14, title: '[Mobil Super] 국산차 맞춤 모빌수퍼 스마트 플러스, 터보 프로텍션', url: 'https://www.youtube.com/embed/__CW39cR2wo'},
        {id: 15, title: '[Mobil] 모빌여지도 - JJ Auto Group', url: 'https://www.youtube.com/embed/c_bZs_qFLmw'},
        {id: 16, title: '[Mobil] 모빌여지도 - KD모터스', url: 'https://www.youtube.com/embed/U_r1C2CWrFU'},
        {id: 17, title: '[Mobil Super] 당신의 즐거움이 멈추지 않도록, Go smart! 모빌수퍼 스마트 플러스 바이크편', url: 'https://www.youtube.com/embed/kUTQ0umkT1o'},
        {id: 18, title: '[Mobil Super] 당신의 즐거움이 멈추지 않도록, Go smart! 모빌수퍼 스마트 플러스 서핑편', url: 'https://www.youtube.com/embed/8Evi5SyfHjk'},
        {id: 19, title: '[Mobil] 모빌여지도 - HJ모터스', url: 'https://www.youtube.com/embed/HCJ3k3vB4-U'},
        {id: 20, title: '[Mobil] 모빌여지도 - 바로카서비스', url: 'https://www.youtube.com/embed/LDaa0ue--kU'},
        {id: 21, title: '[Mobil Super] 하이웨이 프로텍션 최적점도', url: 'https://www.youtube.com/embed/LI088qn2e4w'},
        {id: 22, title: '[Mobil Super] 스마트플러스 해방감', url: 'https://www.youtube.com/embed/3_0tZ-vj-CM'},
        {id: 23, title: '[Mobil Super] 스마트플러스 가혹한 온도', url: 'https://www.youtube.com/embed/AJ-WdyxMRR8'},
        {id: 24, title: '[Mobil Super] 스마트플러스 인스타', url: 'https://www.youtube.com/embed/2lCCmzn7LRc'},
        {id: 25, title: '[Mobil Super] 스마트플러스 도심과 자연', url: 'https://www.youtube.com/embed/ySqH5U5wRbo'},
        {id: 26, title: '[Mobil Super] 하이웨이 프로텍션 시간표', url: 'https://www.youtube.com/embed/d0nlOXJlQQI'},
        {id: 27, title: '[Mobil Super] 하이웨이 프로텍션 워라밸', url: 'https://www.youtube.com/embed/Nzz6CG0z3cU'},
        {id: 28, title: '[Mobil Super] 하이웨이 프로택션 장거리', url: 'https://www.youtube.com/embed/MJNpQbdK8mY'},
        {id: 29, title: '[Mobil Super] 국산차 맞춤 합성유 시리즈', url: 'https://www.youtube.com/embed/CW6uxwA6QmQ'},
      ],
      products: [],
      popupDialog: false,
      popupBanner: {},
      pdfDialog: false,
      documentUrl: '/가격인상공문.pdf'
    }
  },
  computed: {
    swiperWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '93vw'
        case 'sm':
          return '93vw'
        default :
          return '96vw'
      }
    },
    selectYoutubeList() {
      let select = []
      while(select.length < 4) {
        const targetid = Math.floor(Math.random() * this.youtubeList.length);
        const isSelected = select.findIndex(e => e.id === targetid) !== -1 ? true : false
        if(!isSelected) { // 새로 추가되야하는 경우
          select.push(this.youtubeList.find(e => e.id === targetid))
        }
      }
      return select;
    }
  },
  async mounted() {
    const res = await this.axios.get('/api/products/home', {
      validateStatus: function(status) {
        return status < 500;
      }
    });
    await this.$checkStatus(res.status); // status error 처리

    if(res.data.productEvent) {
      this.products = res.data.productEvent.products;
    }

    // this.popupBanner = {
    //   img: require('@/assets/mainPage/popupBanner.jpg'),
    //   link: '',
    // };
    // popup banner 노출여부 체크
    if(this.$store.state.isLogin && !this.$cookies.get('today') && this.popupBanner.img) {
      this.popupDialog = true;
    }
  },
  methods: {
    youtubeLink() {
      window.open('https://youtube.com/playlist?list=PLbdbia2N1AiFNjTS7GKu5JCtgokQp6nRr')
    },
    popupBannerLink() { // popup banner link
      if(this.popupBanner) {
        window.location = this.popupBanner.link;
      }
    },
    async todayNosee() {
      this.popupDialog = false;
      this.$cookies.set("today", "visit", "1d");
    },
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .product-title {
    font-size:18px;
    font-weight:500;
    color:#4f4f4f;
    padding-top: 55px;
    padding-bottom: 47.5px;
  }
}
@media screen and (min-width: 960px) {
  .product-title {
    font-size:26px;
    font-weight:500;
    color:#4f4f4f;
    padding-top: 100px;
    padding-bottom: 70px;
  }
}

.swiper {
  width: 100%;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.swiper-button-prev {
  color: white;
}
.swiper-button-next {
  color: white;
}
// .swiper-pagination-bullet-active {
//   background: white !important;
// }
.swiper-container {
  --swiper-pagination-color: white;
}
</style>