import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/shop/homeView.vue'
import store from '../store/index.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/shop/indexView.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: HomeView,
      },
      { // 로그인 router
        path: 'login',
        name: 'login',
        component: () => import('../views/shop/loginView.vue'),
        beforeEnter(to, from , next) {
          if(!store.state.isLogin) { // 비로그인 상태
            next();
          } else { // 로그인 상태
            window.location.replace('/');
          }
        },
      },
      { // 내정보 router
        path: 'myinfo',
        name: 'myinfo',
        component: () => import('../views/shop/myinfo/indexView.vue'),
        beforeEnter(to, from , next) {
          if(!store.state.isLogin) { // 비로그인 상태
            router.push({name: 'login'}).catch(() => {});
          } else { // 로그인 상태
            next();
          }
        },
        children: [
          {
            path: 'order',
            name: 'myinfo-order',
            component: () => import('../views/shop/myinfo/orderView.vue'),
          },
          {
            path: 'order/detail',
            name: 'myinfo-orderDetail',
            component: () => import('../views/shop/myinfo/orderDetailView.vue'),
          },
          {
            path: 'mileage',
            name: 'myinfo-mileage',
            component: () => import('../views/shop/myinfo/mileageView.vue'),
          },
          {
            path: 'info',
            name: 'myinfo-info',
            component: () => import('../views/shop/myinfo/myinfoView.vue'),
          },
        ]
      },
      { // 상품 메인 페이지
        path: 'products/index',
        name: 'productIndex',
        component: () => import('../views/shop/product/productIndexView.vue'),
        beforeEnter(to, from , next) {
          if(!store.state.isLogin) { // 비로그인 상태
            router.push({name: 'login'}).catch(() => {});
          } else { // 로그인 상태
            next();
          }
        },
      },
      { // 상품 카테고리별 목록 페이지
        path: 'products/list',
        name: 'productList',
        component: () => import('../views/shop/product/productListView.vue'),
        beforeEnter(to, from , next) {
          if(!store.state.isLogin) { // 비로그인 상태
            router.push({name: 'login'}).catch(() => {});
          } else { // 로그인 상태
            next();
          }
        },
      },
      { // 상품 페이지
        path: 'products',
        name: 'products',
        component: () => import('../views/shop/product/productDetailView.vue'),
      },
      { // 상품 주문 페이지
        path: 'order',
        name: 'order',
        component: () => import('../views/shop/order/orderView.vue'),
        beforeEnter(to, from , next) {
          if(!store.state.isLogin) { // 비로그인 상태
            router.push({name: 'login'}).catch(() => {});
          } else { // 로그인 상태
            next();
          }
        },
      },
      { // 상품 주문 페이지
        path: 'testorder',
        name: 'testorder',
        component: () => import('../views/shop/order/orderTestView.vue'),
        beforeEnter(to, from , next) {
          console.log(store.state.user);
          if(store.state.user.userId !== 1) { // 비로그인 상태
            router.push({name: '404error'}).catch(() => {});
          } else { // 로그인 상태
            next();
          }
        },
      },
      { // 상품 주문 결과 페이지
        path: 'order/result',
        name: 'orderResult',
        component: () => import('../views/shop/order/orderResultView.vue'),
        beforeEnter(to, from , next) {
          if(!store.state.isLogin) { // 비로그인 상태
            router.push({name: 'login'}).catch(() => {});
          } else { // 로그인 상태
            next();
          }
        },
      },
      { // 상품 주문 결과 페이지
        path: 'order/popup/result',
        name: 'orderPopupResult',
        component: () => import('../views/shop/order/orderPopupResultView.vue'),
      },
      // { // 상품 주문 페이지 form
      //   path: 'orderform',
      //   name: 'orderform',
      //   component: () => import('../views/shop/order/orderFormView.vue'),
      //   beforeEnter(to, from , next) {
      //     if(!store.state.isLogin) { // 비로그인 상태
      //       router.push({name: 'login'}).catch(() => {});
      //     } else { // 로그인 상태
      //       next();
      //     }
      //   },
      // },
      {
        path: 'cart',
        name: 'cart',
        component: () => import('../views/shop/cartView.vue'),
        beforeEnter(to, from , next) {
          if(!store.state.isLogin) { // 비로그인 상태
            router.push({name: 'login'}).catch(() => {});
          } else { // 로그인 상태
            next();
          }
        },
      },
      {
        path: 'event',
        name: 'event',
        component: () => import('../views/shop/event/eventIndexView.vue'),
      },
      {
        path: 'event/1',
        name: 'event1',
        component: () => import('../views/shop/event/event1View.vue'),
      },
      {
        path: 'terms',
        name: 'terms',
        component: () => import('../views/shop/terms/indexView.vue'),
      },
    ],
  },
  // admin
  {
    path: '/admin',
    component: () => import('../views/admin/indexView.vue'),
    meta: { title: '영일O&C 스토어 관리자'},
    beforeEnter(to, from , next) {
      if(!store.state.isLogin) { // 비로그인 상태
        if(to.name === 'admin-login') {
          next();
        } else {
          // next('/admin/login');
          window.location.replace('/admin/login');
        }
      } else { // 로그인 상태
        if(store.state.user.type === 0) { // 영업자 로그인
          if(to.name === 'admin-login') {
            window.location.replace({name: 'admin-dashboard'});
          } else {
            next();
          }
        }
         else { // 유저 로그인, 접근 권한 없음
          window.location.replace({name: 'home'});
        }
      }
    },
    children: [
      { // 로그인 router
        path: 'login',
        name: 'admin-login',
        component: () => import('../views/admin/loginView.vue'),
      },
      {
        path: '/',
        name: 'admin-dashboard',
        component: () => import('../views/admin/dashboardView.vue'),
      },
      {
        path: 'myinfo',
        name: 'admin-myinfo',
        component: () => import('../views/admin/myinfoView.vue'),
      },
      { // 유저 리스트 router
        path: 'users',
        name: 'admin-users',
        component: () => import('../views/admin/users/usersView.vue'),
        beforeEnter(to, from, next) {
          if(!(from.name === 'admin-user-detail' || from.name === 'admin-user-create')) {
            // 유저 하위 목록에서 뒤로가기로 들어오는 경로 외에는 검색어 초기화
            store.dispatch('setUserSearchKeyword', '');
          }
          next();
        }
      },
      {
        path: 'analisis',
        name: 'admin-analisis',
        component: () => import('../views/admin/AnalisisView.vue')
      },
      { // 특정 유저 정보 조회 router
        path: 'users/detail',
        name: 'admin-user-detail',
        component: () => import('../views/admin/users/userDetailView.vue'),
        // beforeEnter(to, from, next) {
        //   if(from.name !== 'admin-users') {
        //     next('/admin/users');
        //   } else {
        //     next();
        //   }
        // }
      },
      { // 유저 가입 router
        path: 'users/form',
        name: 'admin-user-create',
        component: () => import('../views/admin/users/userCreateFormView.vue'),
        beforeEnter(to, from, next) {
          if(from.name !== 'admin-users') {
            next('/admin/users');
          } else {
            next();
          }
        }
      },
      { // 영업자 리스트 router
        path: 'salesmans',
        name: 'admin-salesmans',
        component: () => import('../views/admin/salesmans/salesmansView.vue'),
        // beforeEnter(to, from, next) {
        //   if(!(from.name === 'admin-user-detail' || from.name === 'admin-user-create')) {
        //     // 유저 하위 목록에서 뒤로가기로 들어오는 경로 외에는 검색어 초기화
        //     store.dispatch('setUserSearchKeyword', '');
        //   }
        //   next();
        // }
      },
      { // 영업자 가입 router
        path: 'salesmans/create',
        name: 'admin-salesmans-create',
        component: () => import('../views/admin/salesmans/salesmanCreateView.vue'),
        beforeEnter(to, from, next) {
          if(from.name !== 'admin-salesmans') {
            next('/admin/salesmans');
          } else {
            next();
          }
        }
      },
      { // 영업자 상세정보 router
        path: 'salesmans/detail',
        name: 'admin-salesmans-detail',
        component: () => import('../views/admin/salesmans/salesmanDetailView.vue'),
        // beforeEnter(to, from, next) {
        //   if(from.name !== 'admin-salesmans') {
        //     next('/admin/salesmans');
        //   } else {
        //     next();
        //   }
        // }
      },
      {
        path: 'products',
        name: 'admin-products',
        component: () => import('../views/admin/products/productView.vue')
      },
      {
        path: 'products/detail',
        name: 'admin-product-detail',
        component: () => import('../views/admin/products/productDetailView.vue')
      },
      {
        path: 'products/create',
        name: 'admin-product-create',
        component: () => import('../views/admin/products/productCreateView.vue')
      },
      {
        path: 'orders',
        name: 'admin-orders',
        component: () => import('../views/admin/products/orderView.vue')
      },
      {
        path: 'cancels',
        name: 'admin-order-cancels',
        component: () => import('../views/admin/products/orderCancelView.vue')
      },
      {
        path: 'board',
        name: 'admin-board',
        component: () => import('../views/admin/boardView.vue')
      },
      {
        path: 'event',
        name: 'admin-event',
        component: () => import('../views/admin/event/event1View.vue')
      },
    ],
  },
  // end -- admin
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    name: '404error',
    component: () => import('../views/pageNotFoundView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  let title = '영일O&C 사업자 전용 스토어';
  to.matched.forEach(e => {
    if(e.meta.title !== undefined) title = e.meta.title;
  });
  // const title = to.meta.title === undefined ? '영일O&C 사업자 전용 스토어' : to.meta.title;
  Vue.nextTick(() => {
    document.title = title;
  });
});

export default router
