// import {utils} from 'xlsx';
import {utils, writeFile} from 'xlsx';

export default {
  install(Vue) {
    // 전역 함수 선언
    // excel 생성
    Vue.prototype.excelExport = async(data, options) => {
      const { instance, dataSize } = await dataToJson(data, options);
      // instance에 만들어진 table을 엑셀파일로 저장
      let config = { raw: true, dateNF: 'yyyy/mm/dd', cellDates: true };
      // let config = { raw: true, type: 'string' };
      let ws = utils.json_to_sheet(instance, config);

      // const { instance, dataSize } = await dataToTable(data, options);
      // let ws = utils.table_to_sheet(instance, config);
      
      // data checking...
      dataFormatCheck(ws, dataSize);
      let wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Sheet1');
      writeFile(wb, options.filename+'.xlsx');
    }
  }
}

// async function dataToTable(data, options) {
//   let dataSize = { s: {r:0, c:0}, e: {r: 0, c: 0} };
//   // data row별 header에 바인딩 될 매핑값 설정
//   let headProps = [];
//   if(Array.isArray(options.headProps)) {
//     headProps = options.headProps;
//   } else if(options.headProps === 'header') {
//     for(let h of options.header) {
//       headProps.push(h.key);
//     }
//   } else {
//     headProps = Object.keys(data[0]);
//   }
//   let visibleHeadProps = headProps;
//   let instance = document.createElement('table')
//   // Header 세팅
//   let header = [];
//   if (!Array.isArray(options.header[0])) {
//     header.push(options.header);
//   } else {
//     header = options.header;
//   }
//   let thead = document.createElement('thead');
//   for(let row of header) {
//     let tr = document.createElement('tr');
//     for(let h of row) {
//       let rowspan = h.rowspan || '1';
//       let colspan = h.colspan || '1';
//       let th = document.createElement('th');
//       th.setAttribute('rowspan', rowspan);
//       th.setAttribute('colspan', colspan);
//       th.innerText = h.name;
//       tr.appendChild(th);

//       if(dataSize.s.c === 0) dataSize.s.c++;
//       dataSize.e.c++;
//     }
//     thead.appendChild(tr);
//     if(dataSize.s.r === 0) dataSize.s.r++;
//     dataSize.e.r++;
//   }
//   instance.appendChild(thead);
//   // Body 세팅
//   let tbody = document.createElement('tbody');
//   for(let row of data) {
//     let tr = document.createElement('tr');
//     for(let cellkey of visibleHeadProps) {
//       let td = document.createElement('td');
//       td.innerText = row[cellkey];
//       tr.appendChild(td);
//     }
//     tbody.appendChild(tr);
//     dataSize.e.r++;
//   }
//   instance.appendChild(tbody);
  
//   return { instanceType: 'table', instance: instance, dataSize: dataSize };
// }

async function dataToJson(data, options) {
  let dataSize = { s: {r:0, c:0}, e: {r:0, c:0} };
  
  // data row별 header에 바인딩 될 매핑값 설정
  let headProps = [];
  if(Array.isArray(options.headProps)) {
    headProps = options.headProps;
  } else if(options.headProps === 'header') {
    for(let h of options.header) {
      headProps.push(h.key);
    }
  } else {
    headProps = Object.keys(data[0]);
  }
  let visibleHeadProps = headProps;
  dataSize.s.c = 1; dataSize.e.c = visibleHeadProps.length; // sheet column length setting
  let instance = [];
  
  // header setting
  let header = options.header; // options.header 가 없는 경우는?
  for(let row of data) {
    let rowdata = {};
    for(let cellkey of visibleHeadProps) {
      const cellname = header.find(e => e.key === cellkey).name;
      rowdata[cellname] = row[cellkey];
      // console.log(`test... cellname: ${cellname}, celldata: ${row[cellkey]}, typeof celldata: ${typeof row[cellkey]}`);
    }
    instance.push(rowdata);
    if(dataSize.s.r === 0) { dataSize.s.r++; dataSize.e.r++; }
    dataSize.e.r++;
  }
  return { instanceType: 'json', instance: instance, dataSize: dataSize };
}

function dataFormatCheck(ws, dataSize) {
  // check number format
  let fmt = '0'; // 변환할 자릿수
  for(let R = dataSize.s.r; R <= dataSize.e.r; ++R) {
    for(let C = dataSize.s.c; C <= dataSize.e.c; ++C) {
      let cell = ws[utils.encode_cell({r:R-1,c:C-1})];
      // console.log(`[${utils.encode_cell({r:R-1,c:C-1})}] {R:${R}, C:${C}}, cell: ${cell.v}, ${typeof cell.v}`);

      if(!cell || cell.t != 'n') continue; // only format numeric cells
      cell.z = fmt;
    }
  }
}